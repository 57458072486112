@font-face {
	font-display: swap;
	font-family: "cabinregular";
	src: url("/static/font/cabin/cabin-regular-webfont.woff2") format("woff2"),
		url("/static/font/cabin/cabin-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: "cabinbold";
	src: url("/static/font/cabin/cabin-bold-webfont.woff2") format("woff2"),
		url("/static/font/cabin/cabin-bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
